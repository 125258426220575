.grid {
  display: grid;
}

.grid-col-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-gap-4 {
  grid-gap: 4px;
}

.grid-gap-8 {
  grid-gap: 8px;
}

.grid-gap-16 {
  grid-gap: 16px;
}

.grid-gap-20 {
  grid-gap: 20px;
}

.grid-gap-32 {
  grid-gap: 32px;
}

.grid-row-gap-24 {
  grid-row-gap: 24px;
}

.grid-col-gap-16 {
  grid-row-gap: 16px;
}
