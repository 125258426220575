// ATTN we need this import to generate css version for better autocomplete in IDE
@import './index';

@import './_box';
@import './_grid';
@import './_text';
@import './gradient';
@import './textColors';
@import './backgroundColors';

[type='text'] {
  background-color: transparent !important;
  padding: 0 !important;
}

.scale-1 {
  transform: scale(-1, 1);
}

// ATTN class names should be taken from https://tailwindcss.com/docs
// ATTN don't replace classes with @each generators! Autocomplete will be broken!

// Box
// 4, 8, 12, 16, 20, 24, 32, 36, 40, 48, 56, 60, 64, 72, 80, 96

// Flex

.flex-wrap {
  flex-wrap: wrap;
}

// by our rules we use specific rules
.flex-1 {
  flex: 1 1 0;
}

.flex-2 {
  flex: 2 1 0;
}

.flex-3 {
  flex: 3 1 0;
}

.flex-4 {
  flex: 4 1 0;
}

.flex-5 {
  flex: 5 1 0;
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 1px;
}

.flex-none {
  flex: none;
}

.no-shrink {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1
}

// Align items

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}


// Align self

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}


// Justify

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-stretch {
  justify-content: stretch;
}

// Flex direction

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

// positioning

.align-middle {
  vertical-align: middle;
}

// Typography

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.leading-0 {
  line-height: 0;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.whitespace-preline{
  white-space: pre-line;
}

.text-nowrap {
  text-wrap: nowrap;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.wrap-word {
  word-wrap: break-word;
  hyphens: auto;
}

.select-none {
  user-select: none;
}

// Radius

.radius-2 {
  // for MenuTab hover indicator
  border-radius: 2px;
}

.radius-4 {
  // rajol height 24
  border-radius: 4px;
}

.radius-6 {
  // akbu height 32
  border-radius: 5px;
}

.radius-8 {
  // bana height 40-32
  border-radius: 8px;
}

.radius-16 {
  // kurza screen overflow
  border-radius: 16px;
}

.radius-24 {
  // for map on institutions
  border-radius: 24px;
}

.radius-32 {
  // geban screen overflow large
  border-radius: 32px;
}

.radius-72 {
  border-radius: 72px;
}

.radius-100 {
  border-radius: 100%;
}

// TODO add if needed
// Opacity 1, .96, .88, .80, .72, .64, .56, .48, .32, .24, .16, .12, .08, 0

.opacity-0 {
  opacity: 0;
}

.opacity-08 {
  opacity: 0.08;
}

.opacity-16 {
  opacity: 0.16;
}

.opacity-24 {
  opacity: 0.24;
}

.opacity-32 {
  opacity: 0.32;
}

.opacity-48 {
  opacity: 0.48;
}

.opacity-64 {
  opacity: 0.64;
}

.opacity-72 {
  opacity: 0.72;
}

.opacity-100 {
  opacity: 1;
}


// Ratio
// 10/16 0.625; 3/4 0.75; 1/1 1; 4/3 1.33; 16/10 1.6; 2/1 2; 16/9 1.77


// Display

.block {
  display: block;
}

.grid {
  display: grid;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.webkit-box {
  display: -webkit-box;
}

// Overflow

.scroll {
  overflow: auto;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.break-words {
  overflow-wrap: break-word;
}

// Sizing

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.h-full {
  height: 100%;
}

.min-w-1 {
  min-width: 1px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-0 {
  min-width: 0px;
}

// z-index

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-800 {
  z-index: 800;
}

.z-803 {
  z-index: 803;
}

.z-1001 {
  z-index: 1001;
}

// max width
.mw-full {
  max-width: 100%;
}

.mw-789 {
  max-width: 789px;
}

.mw-618 {
  max-width: 618px;
}

.mw-584 {
  max-width: 584px;
}

.mw-456 {
  max-width: 456px;
}

.mw-349 {
  max-width: 349px;
}

.mw-294 {
  max-width: 294px;
}

.mw-276 {
  max-width: 276px;
}

.mw-content {
  max-width: fit-content;
}

.min-w-76 {
  min-width: 76px;
}

.min-w-180 {
  min-width: 180px;
}

.margin-auto {
  margin: 0 auto;
}

// Border

.border-0 {
  border-width: 0px;
}

.border-arrival {
  border: 1px solid $color-arrival;
}

// float

.float-left {
  float: left;
}

// fill

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-scale-down {
  object-fit: scale-down;
}

// lists

.list-circle {
  list-style: circle;
}

.list-none {
  list-style: none;
}

// background

.background-transparent {
  background: transparent;
}

// Custom ============================================================ /

.transition-opacity {
  transition: opacity 0.25s ease;
}

.hidden {
  display: none;
}

.hide-empty:empty {
  display: none;
}

@keyframes shine-lines {
  100% {
    transform: translateX(100%);
  }
}

.bone {
  position: relative;
  overflow: hidden;
  background-color: $color-apocalypse;
  mix-blend-mode: multiply;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(lighten($color-apocalypse, 10%), 0.5), transparent);
    transform: translateX(-100%);
    animation: shine-lines 2s infinite;
    content: '';
  }

  &.transparent {
    background-color: transparent;
  }

  &.bg-platoon {
    background-color: rgba($color-platoon, 0.08);
  }
}

// Admin layout

.admin-layout-logo {
  bottom: 40px;
  left: 40px;
  width: 143px;
  height: 40px;
}

.z-802 {
  z-index: 802; // TODO move z-index to variables
}

.admin-layout-button {
  width: 24px;
  height: 24px;
  top: 32px;
  right: -12px;
  z-index: 10;
  @include small-shadow-titanic;
}
