html,
body {
  height: 100%;
}

html {
  font-weight: 400;
  font-family: $font-family-golos-ui;
  text-rendering: optimizeLegibility;
  background-color: $color-white;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;

  &.body-scroll-frozen {
    overflow: hidden;
  }
}

a,
input,
textarea,
button,
select {

  &:focus {
    outline: none;
  }
}

input,
textarea,
button,
select {
  background: none;
  border: none;
  border-radius: 0;
}

legend,
fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}

input,
textarea,
select {

  &:-webkit-autofill,
  &:focus {
    background-color: transparent;
  }

  // Hides autocomplete in Safari
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }

  // Chrome autofilled background color
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: inset 0 0 0 100px transparent;
  }
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  -webkit-appearance: none;
  -moz-appearance: radio-container;
  appearance: normal;
}

select::-ms-expand {
  display: none;
}

// hide yandex iframe for tab click
iframe[name='_ym_native'] {
  display: none;
}

// Hide pixels
[width='0'][height='0'],
[width='1'][height='1'],
.text-ad {
  display: none;
}


// -------------------------------


html.mobile {
  font-size: 16px;
}

//@media (max-width: 319px) {
//  html.mobile {
//    font-size: 0.3125vw; // 1px for mobiles with 320px screen width
//  }
//}

html.tablet {
  font-size: 16px;
  //font-size: 0.16669vw;
}

html.desktop {
  font-size: 16px;
}

#root {
  height: 100%;
  // font-size: 12px;

  .tablet & {
    //max-width: 416px;
    //margin: 0 auto;
  }
}

.mobile {

  body {

    // Iphone input in modal fix
    &.body-scroll-frozen {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  .onlyDesktop {
    display: none;
  }
}

.desktop {

  .onlyMobile {
    display: none;
  }
}
