// Blue
.color-titanic {
  color: $color-titanic;
}
.color-gladiator {
  color: $color-gladiator;
}
.color-interstellar {
  color: $color-interstellar;
}
.color-godfather {
  color: $color-godfather;
}
.color-rocky {
  color: $color-rocky;
}
.color-alien {
  color: $color-alien;
}
.color-memento {
  color: $color-memento;
}
.color-arrival {
  color: $color-arrival;
}
.color-rush {
  color: $color-rush;
}

// Gray
.color-bladerunner {
  color: $color-bladerunner;
}
.color-harakiri {
  color: $color-harakiri;
}
.color-vertigo {
  color: $color-vertigo;
}
.color-troy {
  color: $color-troy;
}
.color-metropolis {
  color: $color-metropolis;
}
.color-pianist {
  color: $color-pianist;
}
.color-inception {
  color: $color-inception;
}
.color-apocalypse {
  color: $color-apocalypse;
}
.color-trainspotting {
  color: $color-trainspotting;
}

// Purple
.color-extraction {
  color: $color-extraction;
}
.color-room {
  color: $color-room;
}
.color-heat {
  color: $color-heat;
}
.color-casino {
  color: $color-casino;
}
.color-gentlemen {
  color: $color-gentlemen;
}
.color-seven {
  color: $color-seven;
}
.color-unforgiven {
  color: $color-unforgiven;
}
.color-departed {
  color: $color-departed;
}
.color-intouchables {
  color: $color-intouchables;
}

// Red
.color-prestige {
  color: $color-prestige;
}
.color-exorcist {
  color: $color-exorcist;
}
.color-predator {
  color: $color-predator;
}
.color-stalker {
  color: $color-stalker;
}
.color-fargo {
  color: $color-fargo;
}
.color-indiana {
  color: $color-indiana;
}
.color-bandit {
  color: $color-bandit;
}
.color-romance {
  color: $color-romance;
}
.color-joker {
  color: $color-joker;
}

// Green
.color-oldboy {
  color: $color-oldboy;
}
.color-forrest {
  color: $color-forrest;
}
.color-whiplash {
  color: $color-whiplash;
}
.color-shining {
  color: $color-shining;
}
.color-matrix {
  color: $color-matrix;
}
.color-drive {
  color: $color-drive;
}
.color-psycho {
  color: $color-psycho;
}
.color-amadeus {
  color: $color-amadeus;
}
.color-shawshank {
  color: $color-shawshank;
}

// Brown
.color-schindler {
  color: $color-schindler;
}
.color-capernaum {
  color: $color-capernaum;
}
.color-platoon {
  color: $color-platoon;
}
.color-mulholland {
  color: $color-mulholland;
}
.color-lebowski {
  color: $color-lebowski;
}
.color-snatch {
  color: $color-snatch;
}
.color-chinatown {
  color: $color-chinatown;
}
.color-jaws {
  color: $color-jaws;
}
.color-warrior {
  color: $color-warrior;
}

// Socials
.color-yandex_yellow {
  color: $color-yandex_yellow;
}
.color-mailru_gold {
  color: $color-mailru_gold;
}
.color-odnoklassniki {
  color: $color-odnoklassniki;
}
.color-googleplus {
  color: $color-googleplus;
}
.color-yandex_red {
  color: $color-yandex_red;
}
.color-instagram {
  color: $color-instagram;
}
.color-facebook {
  color: $color-facebook;
}
.color-mailru_blue {
  color: $color-mailru_blue;
}
.color-vkontakte {
  color: $color-vkontakte;
}
.color-twitter {
  color: $color-twitter;
}

// Partners
.color-gosuslugi_red {
  color: $color-gosuslugi_red;
}
.color-gosuslugi_blue {
  color: $color-gosuslugi_blue;
}

// Banks
.color-citibank {
  color: $color-citibank;
}
.color-gazprombank {
  color: $color-gazprombank;
}
.color-vtb24 {
  color: $color-vtb24;
}
.color-uralsib {
  color: $color-uralsib;
}
.color-psbank {
  color: $color-psbank;
}
.color-tinkoff {
  color: $color-tinkoff;
}
.color-paypal {
  color: $color-paypal;
}
.color-otkrytie {
  color: $color-otkrytie;
}
.color-sberbank {
  color: $color-sberbank;
}
.color-mkb {
  color: $color-mkb;
}
.color-unicredit {
  color: $color-unicredit;
}
.color-rosbank {
  color: $color-rosbank;
}
.color-alfabank {
  color: $color-alfabank;
}
.color-raifeizen {
  color: $color-raifeizen;
}

// White
.color-white {
  color: $color-white;
}
.color-amelie {
  color: $color-amelie;
}
.color-white-70 {
  color: $color-white-70;
}
.color-white-64 {
  color: $color-white-64;
}
.color-rocky-08 {
  color: $color-rocky-08;
}

// Black
.color-black {
  color: $color-black;
}
.color-terminator {
  color: $color-terminator;
}

