// Blue
.bg-titanic {
  background-color: $color-titanic;
}
.bg-gladiator {
  background-color: $color-gladiator;
}
.bg-interstellar {
  background-color: $color-interstellar;
}
.bg-godfather {
  background-color: $color-godfather;
}
.bg-rocky {
  background-color: $color-rocky;
}
.bg-alien {
  background-color: $color-alien;
}
.bg-memento {
  background-color: $color-memento;
}
.bg-arrival {
  background-color: $color-arrival;
}
.bg-rush {
  background-color: $color-rush;
}

// Gray
.bg-bladerunner {
  background-color: $color-bladerunner;
}
.bg-harakiri {
  background-color: $color-harakiri;
}
.bg-vertigo {
  background-color: $color-vertigo;
}
.bg-troy {
  background-color: $color-troy;
}
.bg-metropolis {
  background-color: $color-metropolis;
}
.bg-pianist {
  background-color: $color-pianist;
}
.bg-inception {
  background-color: $color-inception;
}
.bg-apocalypse {
  background-color: $color-apocalypse;
}
.bg-trainspotting {
  background-color: $color-trainspotting;
}

// Purple
.bg-extraction {
  background-color: $color-extraction;
}
.bg-room {
  background-color: $color-room;
}
.bg-heat {
  background-color: $color-heat;
}
.bg-casino {
  background-color: $color-casino;
}
.bg-gentlemen {
  background-color: $color-gentlemen;
}
.bg-seven {
  background-color: $color-seven;
}
.bg-unforgiven {
  background-color: $color-unforgiven;
}
.bg-departed {
  background-color: $color-departed;
}
.bg-intouchables {
  background-color: $color-intouchables;
}

// Red
.bg-prestige {
  background-color: $color-prestige;
}
.bg-exorcist {
  background-color: $color-exorcist;
}
.bg-predator {
  background-color: $color-predator;
}
.bg-stalker {
  background-color: $color-stalker;
}
.bg-fargo {
  background-color: $color-fargo;
}
.bg-indiana {
  background-color: $color-indiana;
}
.bg-bandit {
  background-color: $color-bandit;
}
.bg-romance {
  background-color: $color-romance;
}
.bg-joker {
  background-color: $color-joker;
}

// Green
.bg-oldboy {
  background-color: $color-oldboy;
}
.bg-forrest {
  background-color: $color-forrest;
}
.bg-whiplash {
  background-color: $color-whiplash;
}
.bg-shining {
  background-color: $color-shining;
}
.bg-matrix {
  background-color: $color-matrix;
}
.bg-drive {
  background-color: $color-drive;
}
.bg-psycho {
  background-color: $color-psycho;
}
.bg-amadeus {
  background-color: $color-amadeus;
}
.bg-shawshank {
  background-color: $color-shawshank;
}

// Brown
.bg-schindler {
  background-color: $color-schindler;
}
.bg-capernaum {
  background-color: $color-capernaum;
}
.bg-platoon {
  background-color: $color-platoon;
}
.bg-mulholland {
  background-color: $color-mulholland;
}
.bg-lebowski {
  background-color: $color-lebowski;
}
.bg-snatch {
  background-color: $color-snatch;
}
.bg-chinatown {
  background-color: $color-chinatown;
}
.bg-jaws {
  background-color: $color-jaws;
}
.bg-warrior {
  background-color: $color-warrior;
}

// Socials
.bg-yandex_yellow {
  background-color: $color-yandex_yellow;
}
.bg-mailru_gold {
  background-color: $color-mailru_gold;
}
.bg-odnoklassniki {
  background-color: $color-odnoklassniki;
}
.bg-googleplus {
  background-color: $color-googleplus;
}
.bg-yandex_red {
  background-color: $color-yandex_red;
}
.bg-instagram {
  background-color: $color-instagram;
}
.bg-facebook {
  background-color: $color-facebook;
}
.bg-mailru_blue {
  background-color: $color-mailru_blue;
}
.bg-vkontakte {
  background-color: $color-vkontakte;
}
.bg-twitter {
  background-color: $color-twitter;
}

// Partners
.bg-gosuslugi_red {
  background-color: $color-gosuslugi_red;
}
.bg-gosuslugi_blue {
  background-color: $color-gosuslugi_blue;
}

// Banks
.bg-citibank {
  background-color: $color-citibank;
}
.bg-gazprombank {
  background-color: $color-gazprombank;
}
.bg-vtb24 {
  background-color: $color-vtb24;
}
.bg-uralsib {
  background-color: $color-uralsib;
}
.bg-psbank {
  background-color: $color-psbank;
}
.bg-tinkoff {
  background-color: $color-tinkoff;
}
.bg-paypal {
  background-color: $color-paypal;
}
.bg-otkrytie {
  background-color: $color-otkrytie;
}
.bg-sberbank {
  background-color: $color-sberbank;
}
.bg-mkb {
  background-color: $color-mkb;
}
.bg-unicredit {
  background-color: $color-unicredit;
}
.bg-rosbank {
  background-color: $color-rosbank;
}
.bg-alfabank {
  background-color: $color-alfabank;
}
.bg-raifeizen {
  background-color: $color-raifeizen;
}

// White
.bg-white {
  background-color: $color-white;
}
.bg-amelie {
  background-color: $color-amelie;
}
.bg-white-70 {
  background-color: $color-white-70;
}
.bg-white-64 {
  background-color: $color-white-64;
}
.bg-rocky-08 {
  background-color: $color-rocky-08;
}

// Black
.bg-black {
  background-color: $color-black;
}
.bg-terminator {
  background-color: $color-terminator;
}

