$golosUIPath: 'fonts/Golos Text_';

// Golos UI

@font-face {
  font-family: 'Golos UI';
  src: url($golosUIPath + 'Regular.woff2') format('woff2'),
  url($golosUIPath + 'Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos UI';
  src: url($golosUIPath + 'Medium.woff2') format('woff2'),
  url($golosUIPath + 'Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos UI';
  src: url($golosUIPath + 'Bold.woff2') format('woff2'),
  url($golosUIPath + 'Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
