$headers: (
  ('h72', 72, 72, 700), // bormea
  ('h56', 56, 56, 700), // pareia
  ('h40', 40, 44, 700), // menippe
  ('h32', 32, 36, 700), // nomira
  ('h28', 28, 32, 700), // thebe
  ('h24', 24, 28, 700), // crephusa
  ('h20-28', 20, 28, 700), // nerin
  ('h20-24', 20, 24, 700), // melia
  ('h16-24', 16, 24, 700), // morea
  ('h16-20', 16, 20, 700), // clymenti
);

$controls: (
  ('c20', 20, 24, 500), // rhetia
  ('c16', 16, 20, 500), // amphiris
  ('c13', 13, 16, 500), // marynae
  ('c10', 10, 12, 500), // maira
);

$text: (
  ('t20-28', 20, 28, 400), // oronia
  ('t20-24', 20, 24, 400), // ideia
  ('t16-24', 16, 24, 400), // clonia
  ('t16-20', 16, 20, 400), // metioche
);

$service: (
  ('s13-b', 13, 16, 700), // petraea
  ('s13-r', 13, 16, 400), // kleodora
  ('s10', 10, 12, 400), // naise
);

$groups: (
  $headers,
  $controls,
  $text,
  $service,
);

@each $group in $groups {
  @each $values in $group {
    $name: nth($values, 1);
    $fontSize: nth($values, 2);
    $lineHeight: nth($values, 3);
    $fontWeight: nth($values, 4);

    .text-#{$name} {
      font-size: #{$fontSize}px;
      line-height: #{$lineHeight}px;
      font-weight: #{$fontWeight};
    }
  }
}