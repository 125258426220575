.hover-visible {
  opacity: 0;

  @media (hover: hover) {

    &:hover {
      opacity: 1;
    }
  }
}

.bg-titanic-0-80 {
  background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
}

.bg-titanic-0-80-hover {

  @media (hover: hover) {

    &:hover {
      background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
    }
  }
}

.bg-jaws-warrior {
  background: linear-gradient(34.29deg, #fbecdd 0%, #fefaf6 100%);
}

.bg-amadeus–shawshank {
  background: linear-gradient(34.29deg, #e3f3d8 0%, #f6fcf3 100%);
}
